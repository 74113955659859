import {
  Background,
  CFlex,
  CMargin,
  DetailsLtr,
  DetailsRtl,
  DText,
  Flex,
  IconWrapper,
} from '../../../styles/CommonStyles'
import { AcceptUserRow, TextArea, UserDetailsBox } from '../../../styles/main/user'
import Text from '../../../core/utils/Text'
import { BiCheck } from 'react-icons/bi'
import { RiCloseLine } from 'react-icons/ri'
import { LineBreak } from '../../../styles/main/MainCommonStyles'
import {
  useChangeCardToIban,
  useNSZ,
  useRequestBankKyc,
  useRequestFinnotechBankKyc,
  useRequestZibalBankKyc,
  useVerifyBankAccount,
} from '../../../core/services/react-query/user'
import ActionButton from '../../common/buttons/ActionButton'
import { useEffect, useState } from 'react'
import { useMainContext } from '../../../core/contexts/main'
import getBankInfo from '../../../core/packages/bank-service/Bank'
import BankCard from '../../../core/packages/bank-service/BankCard'
import ModalLayout from '../../layout/main/ModalLayout'
import BasicModal from '../../modals/BasicModal'

const UserBankActions = (props) => {
  const { mode, user, refetch, closeActionMode, kycMode = false } = props
  const { action, bank, bankUser } = mode
  const bankInfo = getBankInfo(bank?.cardNo)

  const { lang } = useMainContext()
  const [rejectNote, setRejectNote] = useState('')

  const [submitModal, setSubmitModal] = useState(false)
  const closeSubmitModal = () => setSubmitModal(false)
  const openSubmitModal = () => setSubmitModal(true)

  const {
    data: bankKyc,
    isLoading: bankKycLoading,
    mutate: requestBankKyc,
    isSuccess: bankKycSuccess,
  } = useRequestBankKyc()

  const {
    data: fBankKyc,
    isLoading: fBankKycLoading,
    mutate: fRequestBankKyc,
    isSuccess: fBankKycSuccess,
    isError: fBankKycError,
  } = useRequestFinnotechBankKyc()

  const {
    isLoading: changingBankStatus,
    mutate: changeBankStatus,
    isSuccess: bankStateChanged,
  } = useVerifyBankAccount()

  /* zibal */
  const {
    data: zibalCardToIban,
    mutate: changeCardToIban,
    isLoading: changeCardToIbanLoading,
    isError: cardToIbanError,
    isSuccess: cardToIbanSuccess,
  } = useChangeCardToIban()
  const {
    data: zibalKyc,
    mutate: requestZibalBankKyc,
    isLoading: requestZibalBankKycLoading,
    isError: zibalKycError,
    isSuccess: zibalKycSuccess,
  } = useRequestZibalBankKyc()
  const zibalCardToIbanData = zibalCardToIban?.data?.data
  const zibalKycData = zibalKyc?.data?.data

  const bankKycData = bankKyc?.data?.data
  const fBankKycData = fBankKyc?.data?.data

  const onRequestInquiry = () => {
    requestBankKyc({
      idNo: bankUser?.idNo || user?.idNo,
      cardNo: bank?.cardNo,
      shebaNo: bank?.shebaNo,
    })
  }

  const onRequestFinnoInquiry = () => {
    fRequestBankKyc({
      mobile: bankUser?.mobile,
      cardNo: bank?.cardNo,
    })
  }

  const onRequestZibalInquiry = () => {
    changeCardToIban({ cardNo: bank.cardNo })
    requestZibalBankKyc({ iban: bank.shebaNo })
  }

  const StatusIcon = ({ match }) => {
    return (
      <>
        {match ? (
          <BiCheck color={'#37b88b'} size={22} />
        ) : (
          <RiCloseLine color={'#f6465d'} size={22} />
        )}
      </>
    )
  }

  const Status = ({ match, success, error = false }) => {
    if (success) {
      return <StatusIcon match={match} />
    } else if (error) {
      return <StatusIcon />
    } else {
      return (
        <DText main fontSize={'s'}>
          <Text tid={'not-inquired'} />
        </DText>
      )
    }
  }

  const onSubmitClicked = () => {
    if (!!rejectNote || mode.action === 'accept-bank') {
      openSubmitModal()
    }
  }

  const onChangeBankAccountStatus = () => {
    const status = action === 'accept-bank' ? 'VERIFIED' : 'REJECTED'
    const payload = {
      data: {
        status,
      },
      bankId: bank?.id,
    }
    if (kycMode) {
      payload.userId = bankUser?._id
    } else {
      payload.userId = user?._id || bank?.bank?._id
    }
    if (rejectNote) payload.data.rejectedReason = rejectNote
    changeBankStatus(payload)
  }

  useEffect(() => {
    if (bankStateChanged) {
      closeSubmitModal()
      closeActionMode()
      refetch && refetch()
    }
  }, [bankStateChanged])

  // national card and sheba no
  const { data: nszData, mutate: nszMutate, isError: nszError, isLoading: nszLoading } = useNSZ()
  const onRequestNsz = () => {
    nszMutate({
      birthDate: user?.birthDate || bank?.bank?.birthDate,
      idNo: user?.idNo || bank?.bank?.idNo,
      iban: bank?.shebaNo,
    })
  }

  return (
    <CFlex fw>
      <CMargin margin={'15px'} />
      <Flex fw justify={'flex-start'}>
        <Flex style={{ padding: '0 10px' }}>
          <IconWrapper onClick={closeActionMode}>
            {lang === 'en' ? <DetailsRtl size={24} /> : <DetailsLtr size={24} />}
          </IconWrapper>
        </Flex>
        <DText primary fontSize={'b'}>
          <Text tid={action} />
        </DText>
      </Flex>
      <CMargin margin={'10px'} />
      {bankInfo && <BankCard bankInfo={bankInfo} bankAccount={bank} />}
      <CMargin margin={'10px'} />
      {mode.action === 'accept-bank' ? (
        <>
          <Flex fw wrap align={'stretch'}>
            <UserDetailsBox>
              <Flex fw justify={'space-between'}>
                <Background bg={'tInputBg'} style={{ padding: '10px', borderRadius: '4px' }}>
                  <DText main fontSize={'s'}>
                    <Text tid={'self-statement'} />
                  </DText>
                  <DText main fontSize={'s'}>
                    <Text tid={'status'} />
                  </DText>
                </Background>
              </Flex>
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'firstName'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {user?.firstName}
                  </DText>
                </Flex>
                <StatusIcon match={!!user?.firstName} />
              </AcceptUserRow>
              <LineBreak />
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'lastName'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {user?.lastName}
                  </DText>
                </Flex>
                <StatusIcon match={!!user?.lastName} />
              </AcceptUserRow>
              <LineBreak />
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'card-number'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bank?.cardNo}
                  </DText>
                </Flex>
                <StatusIcon match={!!bank?.cardNo} bank={bankKycSuccess} />
              </AcceptUserRow>
              <LineBreak />
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'sheba-number'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bank?.shebaNo}
                  </DText>
                </Flex>
                <StatusIcon match={!!bank?.shebaNo} />
              </AcceptUserRow>
              <CMargin margin={'10px'} />
            </UserDetailsBox>

            {/* faraboom */}
            <UserDetailsBox>
              <Flex fw justify={'space-between'}>
                <Background bg={'tInputBg'} style={{ padding: '10px', borderRadius: '4px' }}>
                  <DText main fontSize={'s'}>
                    <Text tid={'user-inquiry-faraboom'} />
                  </DText>
                  <DText main fontSize={'s'}>
                    <Text tid={'status'} />
                  </DText>
                </Background>
              </Flex>
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'firstName'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bankKycData?.first_name}
                  </DText>
                </Flex>
                <Status match={!!bankKycData?.first_name} success={bankKycSuccess} />
              </AcceptUserRow>
              <LineBreak />
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'lastName'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bankKycData?.last_name}
                  </DText>
                </Flex>
                <Status match={!!bankKycData?.last_name} success={bankKycSuccess} />
              </AcceptUserRow>
              <LineBreak />
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'card-number'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bank?.cardNo}
                  </DText>
                </Flex>
                <Status match={!!bank?.cardNo} success={bankKycSuccess} />
              </AcceptUserRow>
              <LineBreak />
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'sheba-number'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bankKycData?.iban}
                  </DText>
                </Flex>
                <Status match={bankKycData?.iban} success={bankKycSuccess} />
              </AcceptUserRow>
              <CMargin margin={'10px'} />
            </UserDetailsBox>

            {/* finnotech */}
            <UserDetailsBox>
              <Flex fw justify={'space-between'}>
                <Background bg={'tInputBg'} style={{ padding: '10px', borderRadius: '4px' }}>
                  <DText main fontSize={'s'}>
                    <Text tid={'user-inquiry-finno'} />
                  </DText>
                  <DText main fontSize={'s'}>
                    <Text tid={'status'} />
                  </DText>
                </Background>
              </Flex>
              <CMargin margin={'10px'} />
              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'card-number'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bank?.cardNo}
                  </DText>
                </Flex>
                <Status
                  match={!!bank?.cardNo}
                  success={fBankKycData?.result?.isValid}
                  error={fBankKycError}
                />
              </AcceptUserRow>
              <LineBreak />
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'sheba-number'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bank?.shebaNo}
                  </DText>
                </Flex>
                <Status
                  match={bank?.shebaNo}
                  success={zibalKycData?.data?.data?.result === 1}
                  error={zibalKycError}
                />
              </AcceptUserRow>
              <CMargin margin={'10px'} />
            </UserDetailsBox>

            {/* zibal */}
            <UserDetailsBox>
              <Flex fw justify={'space-between'}>
                <Background bg={'tInputBg'} style={{ padding: '10px', borderRadius: '4px' }}>
                  <DText main fontSize={'s'}>
                    <Text tid={'user-inquiry-zibal'} />
                  </DText>
                  <DText main fontSize={'s'}>
                    <Text tid={'status'} />
                  </DText>
                </Background>
              </Flex>
              <CMargin margin={'10px'} />
              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'card-number'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bank?.cardNo}
                  </DText>
                </Flex>
                <Status
                  match={bank.shebaNo === zibalCardToIbanData?.data?.IBAN}
                  success={zibalCardToIbanData?.result === 1}
                  error={cardToIbanError}
                />
              </AcceptUserRow>
              <LineBreak />
              <CMargin margin={'10px'} />

              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'sheba-number'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bank?.shebaNo}
                  </DText>
                </Flex>
                <Status
                  match={zibalKycData?.result === 1}
                  success={zibalKycSuccess}
                  error={zibalKycError}
                />
              </AcceptUserRow>
              <CMargin margin={'10px'} />
            </UserDetailsBox>

            {/* zibal national id & sheba no */}
            <UserDetailsBox>
              <Flex fw justify={'space-between'}>
                <Background bg={'tInputBg'} style={{ padding: '10px', borderRadius: '4px' }}>
                  <DText main fontSize={'s'}>
                    <Text tid={'zibal-2-inquiry'} />
                  </DText>
                  <DText main fontSize={'s'}>
                    <Text tid={'status'} />
                  </DText>
                </Background>
              </Flex>

              <CMargin margin={'10px'} />
              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'sheba-number'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {bank?.shebaNo}
                  </DText>
                </Flex>
                <Status
                  match={nszData?.data?.data?.data?.matched}
                  success={nszData?.data?.data?.result === 1}
                  error={nszError}
                />
              </AcceptUserRow>
              <LineBreak />

              <CMargin margin={'10px'} />
              <AcceptUserRow>
                <Flex>
                  <DText main fontSize={'s'}>
                    <Text tid={'idNo'} /> :
                  </DText>
                  <DText main style={{ margin: '0 8px' }}>
                    {user?.idNo || bank?.bank?.idNo}
                  </DText>
                </Flex>
                <Status
                  match={nszData?.data?.data?.data?.matched}
                  success={nszData?.data?.data?.result === 1}
                  error={nszError}
                />
              </AcceptUserRow>
            </UserDetailsBox>
          </Flex>
          <CMargin margin={'20px'} />
          <ActionButton
            active={!bankKycData}
            onClick={onRequestInquiry}
            loading={bankKycLoading}
            width={'300px'}
            height={'42px'}
            color={'#818F9F'}
          >
            <Text tid={'request-inquiry-faraboom'} />
          </ActionButton>
          <CMargin margin={'6px'} />
          <ActionButton
            active={!fBankKycData}
            onClick={onRequestFinnoInquiry}
            loading={fBankKycLoading}
            width={'300px'}
            height={'42px'}
            color={'#818F9F'}
          >
            <Text tid={'request-inquiry-finno'} />
          </ActionButton>
          <CMargin margin={'6px'} />
          <ActionButton
            active={!zibalCardToIbanData && !zibalCardToIbanData}
            onClick={onRequestZibalInquiry}
            loading={requestZibalBankKycLoading || changeCardToIbanLoading}
            width={'300px'}
            height={'42px'}
            color={'#818F9F'}
          >
            <Text tid={'request-inquiry-zibal'} />
          </ActionButton>

          <CMargin margin={'6px'} />
          <ActionButton
            active={!nszData}
            onClick={onRequestNsz}
            loading={nszLoading}
            width={'300px'}
            height={'42px'}
            color={'#818F9F'}
          >
            <Text tid={'request-inquiry-nsz'} />
          </ActionButton>
        </>
      ) : (
        <CFlex width={'300px'}>
          <CMargin margin={'10px'} />
          <TextArea
            value={rejectNote}
            onChange={(e) => setRejectNote(e?.target?.value)}
            placeholder={lang === 'en' ? 'Bank account rejected because ...' : 'دلیل رد حساب بانکی'}
          />
          <CMargin margin={'10px'} />
        </CFlex>
      )}
      <CMargin margin={'10px'} />
      {action === 'reject-bank' || !bank?.verifyAt ? (
        <ActionButton
          height={'42px'}
          width={'300px'}
          active={!!rejectNote || action === 'accept-bank'}
          onClick={onSubmitClicked}
        >
          <Text tid={mode.action} />
        </ActionButton>
      ) : (
        <DText color={'#819F82'}>
          <Text tid={'bank-verified'} />
        </DText>
      )}

      <CMargin margin={'10px'} />

      <ModalLayout open={submitModal} onClose={closeSubmitModal} width={'580px'}>
        <BasicModal
          head={action}
          onClose={closeSubmitModal}
          onSubmit={onChangeBankAccountStatus}
          loading={changingBankStatus}
        />
      </ModalLayout>
    </CFlex>
  )
}

export default UserBankActions
