import { CFlex, CMargin, DText } from "../../../../../styles/CommonStyles";
import Select from "../../../../common/dropdown/Select";
import { useAvailableCoins } from "../../../../../core/services/react-query/setting";
import { useEffect, useState } from "react";
import Text from "../../../../../core/utils/Text";
import { deepCopy, stringToNumber } from "../../../../../core/utils/common";
import Input from "../../../../common/input/Input";
import { TextArea, UserDetailSettingBox } from "../../../../../styles/main/user";
import MultiUpload from "../../../../common/input/MultiUpload";
import ActionButton from "../../../../common/buttons/ActionButton";
import {
    useCreateMultiSignature,
    useUploadMultiSignatureImages
} from "../../../../../core/services/react-query/multi-signature";
import { useQueryContext } from "../../../../../core/contexts/query";

const tags = [
    "تامین سرمایه",
    "حقوق ویدنکس",
    "هزینه‌های ویدنکس",
    "کد ۱",
    "کد ۲",
    "کد ۳",
    "کد ۴",
    "کد ۵",
]

const ChangeBalance = ({ user, changeType }) => {

    const { setToast } = useQueryContext()
    const { data: availableCoins } = useAvailableCoins()

    const {
        mutateAsync: uploadImages,
        isLoading: uploading,
        isSuccess: uploadSuccess,
        reset: resetRequest
    } = useUploadMultiSignatureImages()

    const {
        mutate: createSignature,
        isLoading: creating,
        isSuccess: created
    } = useCreateMultiSignature()


    const [currency, setCurrency] = useState(null)
    const [tag, setTag] = useState(null)
    const [readyToCreate, setReadyToCreate] = useState(false)
    const [mutatingIndex, setMutatingIndex] = useState(-1)
    const [downloadedLinks, setDownloadedLinks] = useState([])
    const [files, setFiles] = useState([])
    const [validAction, setValidAction] = useState(false)
    const initialState = {
        type: 'balance',
        params: {
            action: changeType,
            currency: '',
            volume: '',
            userId: user?._id,
        },
        note: '',
        tag: '',

        files: []
    }
    const [balanceData, setBalanceData] = useState(initialState)

    useEffect(() => {
        if (availableCoins) {
            const _irt = availableCoins.find(item => item.id === 'irt')
            if (!_irt) {
                availableCoins.push({
                    id: 'irt',
                    fa: 'تومان',
                    name: 'Tooman'
                })
            }
        }
    }, [availableCoins])

    useEffect(() => {
        if (currency) {
            onInputValueChange(currency, 'currency')
        }
    }, [currency])

    const resetData = () => {
        setBalanceData(initialState)
        setTag(null)
        setCurrency(null)
        setFiles([])
        setDownloadedLinks([])
        setMutatingIndex(-1)
        setReadyToCreate(false)
        resetRequest()
    }

    useEffect(() => {
        resetData()
    }, [changeType])

    useEffect(() => {
        if (created) {
            resetData()
        }
    }, [created])

    useEffect(() => {
        const valid =
            balanceData.note &&
            balanceData.params.currency &&
            balanceData.params.volume
        setValidAction(valid)
    }, [balanceData])

    useEffect(() => {

        if ((downloadedLinks.length === files.length) && readyToCreate) {
            const payload = deepCopy(balanceData)
            payload.params.volume = stringToNumber(payload.params.volume)
            payload.files = downloadedLinks
            createSignature(payload)
            setReadyToCreate(false)
        }

    }, [readyToCreate])

    const onInputValueChange = (v, type) => {

        const newData = deepCopy(balanceData)
        switch (type) {
            case 'currency':
                newData.params.currency = v.id
                break
            case 'volume':
                newData.params.volume = v
                break
            case 'note':
                newData.note = v
                break
            case 'tag':
                newData.tag = v
                break
            case 'files':
                setFiles(v)
                break
            default:
                break
        }
        setBalanceData(newData)
    }

    const onCurrencyChange = (idx) => {
        setCurrency(availableCoins[idx])
        onInputValueChange(availableCoins[idx], 'currency')
    }
    const onTagChange = (idx) => {
        setTag(tags[idx])
        onInputValueChange(tags[idx], 'tag')
    }

    let tries = 0
    const uploadImageAndCacheLink = async (i) => {
        const formData = new FormData();
        formData.append('attachment', files[i])
        setMutatingIndex(i)
        try {
            const link = await uploadImages(formData)
            if (link?.data?.success) {
                setDownloadedLinks(state => ([
                    ...state, { link: link?.data?.data?.link }
                ]))
                return true
            }
        } catch (e) {
            tries += 1
            if (tries < 3) {
                await uploadImageAndCacheLink(i)
            } else {
                tries = 0
                return false
            }
        }
    }

    const onSubmitClicked = async () => {
        if (validAction) {
            for (let i = 0; i < files.length; i++) {
                const success = await uploadImageAndCacheLink(i)
                if (!success) {
                    resetData()
                    setToast({
                        message: 'try-later',
                        show: true, isError: true
                    })
                    return
                }
            }
            setReadyToCreate(true)
        } else {
            setToast({
                message: 'fill-input-errors',
                show: true, isError: true
            })
        }
    }

    return (
        <CFlex fw>
            <UserDetailSettingBox>
                <CMargin margin={'8px'} />
                <DText main fontSize={'s'}>
                    <Text tid={'currency'} />
                </DText>
                <CMargin margin={'4px'} />
                <Select
                    options={availableCoins ? availableCoins : []}
                    value={currency}
                    onValueChange={onCurrencyChange}
                    placeHolder={'select-currency'}
                    fontSize={'s'}
                    width={'100%'}
                    height={'42px'}
                    isCoin
                />
                <CMargin margin={'8px'} />
                <DText main fontSize={'s'}>
                    <Text tid={'volume'} />
                </DText>
                <CMargin margin={'4px'} />
                <Input
                    value={balanceData.params.volume}
                    onInputChange={(v) => onInputValueChange(v, 'volume')}
                    size={'small'}
                    number
                    padding={'0'}
                />
                <DText main fontSize={'s'}>
                    <Text tid={'note'} />
                </DText>
                <CMargin margin={'4px'} />
                <Select
                    options={tags}
                    value={tag}
                    onValueChange={onTagChange}
                    placeHolder={'select-tag'}
                    fontSize={'s'}
                    width={'100%'}
                    height={'42px'}
                />
                <CMargin margin={'8px'} />
                <TextArea
                    value={balanceData.note}
                    onChange={(e) => onInputValueChange(e?.target?.value, 'note')}
                />
                <CMargin margin={'8px'} />
                <MultiUpload
                    files={files}
                    onFilesChange={(files) => onInputValueChange(files, 'files')}
                    uploading={uploading}
                    success={uploadSuccess}
                    index={mutatingIndex}
                />
                <CMargin margin={'10px'} />
                <ActionButton
                    active={validAction}
                    onClick={onSubmitClicked}
                    loading={uploading || creating}
                >
                    <Text tid={'submit'} />
                </ActionButton>
            </UserDetailSettingBox>
        </CFlex>
    )
}


export default ChangeBalance
