import { Flex, IconWrapper, PageCard } from '../../styles/CommonStyles'
import ListLayout from '../../components/layout/main/ListLayout'
import { useState } from 'react'
import { useAclContext } from '../../core/contexts/acl'
import Tooltip from '../../components/common/utils/Tooltip'
import { AddIcon } from '../../styles/main/setting'
import { useNavigate } from 'react-router-dom'
import Tabbar from '../../components/common/tabs/Tabbar'
import AllUsers from './AllUsers'
import NotVerifiedUsers from './NotVerifiedUsers'
import RejectedUsers from './RejectedUsers'

const Users = () => {
  const navigate = useNavigate()

  const { permissions } = useAclContext()
  const hasAddUser = permissions?.user?.write

  const [activeTab, setActiveTab] = useState(1)

  const onAddUser = () => {
    navigate('/users/add-user')
  }

  const comps = [AllUsers, NotVerifiedUsers, RejectedUsers]

  const ActiveComp = comps[activeTab - 1]

  return (
    <ListLayout>
      <PageCard>
        {hasAddUser && (
          <Flex fw justify={'justify-between'}>
            <Tabbar
              active={activeTab}
              tabs={['all-users', 'not-verified-users', 'rejected-users']}
              onTabClicked={(idx) => setActiveTab(idx)}
            />
            <Tooltip content={'add-user'}>
              <IconWrapper onClick={onAddUser}>
                <AddIcon size={24} />
              </IconWrapper>
            </Tooltip>
          </Flex>
        )}

        <ActiveComp />
      </PageCard>
    </ListLayout>
  )
}

export default Users
