import { BiTrash } from 'react-icons/bi'
import Text from '../../../../../core/utils/Text'
import { CFlex, DText, Flex, Margin, MBtn, Row } from '../../../../../styles/CommonStyles'
import { RespIndex } from '../../../../../styles/main/MainCommonStyles'
import DateTime from '../../../../common/utils/DateTime'
import RTableLayout from '../../../layout/RTableLayout'

export default function RNotificationTable({ data, setDeleteModal }) {
    const { data: notifications } = data

    return (
        <RTableLayout data={data}>
            {notifications?.data?.map((item, index) => {
                return (
                    <Row cs={'100%'} index={index} key={item._id}>
                        <CFlex align='stretch'>
                            <Flex justify='space-between'>
                                <DText>
                                    <Text tid={item.type} />
                                </DText>
                                <DText
                                    margin='5px 0'
                                    color={
                                        item.priority === 'HIGH'
                                            ? '#1ce087'
                                            : item.priority === 'MEDIUM'
                                                ? '#42958D'
                                                : '#a12356'
                                    }>
                                    <Text tid={item.priority} />
                                </DText>
                                <DateTime dt={item.createdAt} />
                            </Flex>
                            <CFlex align='flex-start'>
                                <DText fontSize='m' margin='0 0 8px'>
                                    {item.title?.trim() || (
                                        <Text tid='no-title' />
                                    )}
                                </DText>
                                <DText fontSize='ss'>
                                    {item.body?.trim() || (
                                        <Text tid='no-description' />
                                    )}
                                </DText>
                            </CFlex>
                            <Margin />
                            <Flex justify="space-between">
                                <RespIndex>{index + 1}</RespIndex>
                                <button onClick={() => { setDeleteModal({ open: true, data: item }) }}>
                                    <BiTrash size={24} color='#c33' />
                                </button>
                            </Flex>
                        </CFlex>

                    </Row>
                )
            })}
        </RTableLayout>
    )
}
