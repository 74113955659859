import { useMutation, useQuery } from "react-query";
import { normalFetch } from "../../fetch-api/get";
import { postApi } from "../../fetch-api/post";
import { useQueryContext } from "../../../contexts/query";
import { deleteApi } from "../../fetch-api/delete";


const NOTIF_KEYS = {
    NOTIF_CONFIG: 'notification-config',
    CREATE_NOTIF: 'create-notification',
    DELETE_NOTIF: 'delete-notification'
}


const useGetNotificationConfig = () => {

    return useQuery(
        NOTIF_KEYS.NOTIF_CONFIG, () => normalFetch('configs/notification'),
        {
            select: res => res?.data?.data
        }
    )
}


const useCreateNotification = () => {
    const { queryClient } = useQueryContext()


    return useMutation(
        NOTIF_KEYS.CREATE_NOTIF, (data) => postApi(data, 'notifications'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('get-all-notifications')
            }
        }
    )
}

const useDeleteNotification = (onSuccess) => {

    return useMutation(
        NOTIF_KEYS.DELETE_NOTIF, (id) => deleteApi(`notifications/${id}`),
        {
            onSuccess: () => { onSuccess() }
        }
    )
}


export {
    useGetNotificationConfig,
    useCreateNotification,
    useDeleteNotification
}
