import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UserDetailListWrapper } from '../../../../styles/main/user'
import FilterLayout from '../../../layout/filter/FilterLayout'
import { useWindowSize } from '../../../../core/hooks/common/useWindowSize'
import NotificationTable from '../../reports/notifications/NotificationTable'
import { useGetNotifications } from '../../../../core/services/react-query/report/notifications'
import {
    CacheKeys,
    userNotificationsOptions,
} from '../../../../core/constants/filter'
import { TABLET_SIZE } from '../../../../core/constants/common'
import RNotificationTable from '../../../responsive/main/reports/notifications/RNotificationTable'
import ModalLayout from '../../../layout/main/ModalLayout'
import BasicModal from '../../../modals/BasicModal'
import { useDeleteNotification } from '../../../../core/services/react-query/notification'

export default function UserNotifications() {
    const params = useParams()
    const { width } = useWindowSize()
    const [deleteModal, setDeleteModal] = useState({ open: false, data: null });
    const closeDeleteModal = () => setDeleteModal({ open: false, data: null })

    const { isLoading: deleteLoading, mutate: deleteMutation, isSuccess: isNotifDeleted } = useDeleteNotification(closeDeleteModal)

    const [notifications, setNotifications] = useState({
        loading: true,
        data: null,
    })

    const onQuerySuccess = (res) => {
        setNotifications(res)
    }
    const Component =
        width > TABLET_SIZE ? NotificationTable : RNotificationTable

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useGetNotifications}
                shouldRefetch={isNotifDeleted}
                options={userNotificationsOptions}
                cache={CacheKeys.USER_NOTIFICATIONS}
                onQuerySuccess={onQuerySuccess}
                extra={{ receptorId: params.id }}>
                <Component data={notifications} setDeleteModal={setDeleteModal} />
            </FilterLayout>
            <ModalLayout width={'580px'} open={deleteModal.open} onClose={closeDeleteModal}>
                <BasicModal
                    head={'delete-nofit'}
                    onClose={closeDeleteModal}
                    onSubmit={() => {
                        deleteMutation(deleteModal.data._id);
                    }}
                    loading={deleteLoading}
                />
            </ModalLayout>
        </UserDetailListWrapper>
    )
}
