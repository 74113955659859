import { UserActionWrapper, UserDetailListWrapper, UserSettingBtn } from "../../../../../styles/main/user";
import { Flex } from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import { useState } from "react";
import AcceptUser from "./AcceptUser";
import RejectUser from "./RejectUser";
import SendNotification from "./SendNotification";
import SendToAllNotification from "./SendToAllNotification";
import Remove2fa from "./Remove2fa";
import BlockAccess from "./BlockAccess";
import { useLocation } from "react-router-dom";
import ChangeBalance from "./ChangeBalance";
import RemoveOTP from "./RemoveOTP";
import AddStaking from './AddStaking'


const UserSetting = (props) => {

    const location = useLocation()

    const navigateActions = {
        accept: 0,
        reject: 1,
        'increase-balance': 2,
        'decrease-balance': 3
    }

    const [active, setActive] = useState(
        location.state ? navigateActions[location.state.action] : -1
    )

    const actions = [
        { name: 'accept-user', color: '#819F82' },
        { name: 'reject-user', color: '#9F819E' },
        { name: 'increase-balance', color: '#818F9F' },
        { name: 'decrease-balance', color: '#82819F' },
        { name: 'send-notification', color: '#818F9F' },
        // { name: 'send-to-all-notification', color: '#808D7C' },
        { name: 'remove-2fa', color: '#9F9181' },
        { name: 'block-access', color: '#9F8281' },
        { name: 'remove-otp', color: '#91514f' },
        { name: 'add-staking', color: '#819f82' },
    ]

    const comps = [
        AcceptUser,
        RejectUser,
        ChangeBalance,
        ChangeBalance,
        SendNotification,
        // SendToAllNotification,
        Remove2fa,
        BlockAccess,
        RemoveOTP,
        AddStaking,
    ]

    const UserAction = comps[active]

    return (
        <UserDetailListWrapper>
            <Flex fw wrap>
                {actions.map((action, idx) => (
                    <UserSettingBtn
                        color={action.color}
                        onClick={() => setActive(idx)}
                        active={idx === active}
                    >
                        <Flex fw fh>
                            <Text tid={action.name} />
                        </Flex>
                    </UserSettingBtn>
                ))}
            </Flex>
            {active !== -1 &&
                <UserActionWrapper>
                    <UserAction
                        {...props}
                        changeType={active === 2 ? 'INCREASE' : 'DECREASE'}
                    />
                </UserActionWrapper>
            }
        </UserDetailListWrapper>
    )
}

export default UserSetting
