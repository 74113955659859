import { useAclContext } from "../../../contexts/acl";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { rejectFiatTransactionOperation, transactionOperations } from "../../../constants/operation";


const useBankTransactions = () => {

    const params = useParams()
    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions.banking?.write

    const modalInitial = {
        action: null,
        open: false,
        transaction: null,
        type: 'banking'
    }
    const [modal, setModal] = useState(modalInitial)

    const onDetailsClicked = (transaction) => {
        if (params.tab)
            navigate(`/users/${params.id}/${params.tab}/${transaction._id}`)
        else
            navigate(`/reports/bank-transactions/${transaction._id}`)
    }

    const onOptionClicked = (idx, transaction) => {
        const action = transaction?.flow === 'withdraw' ? transactionOperations[idx] : rejectFiatTransactionOperation[idx]
        setModal({
            open: true,
            action: action,
            transaction,
            type: 'banking'
        })
    }

    const onModalClose = () => {
        if (modal.open) {
            setModal(modalInitial)
        }
    }

    const getTransactionOptions = (transaction) => {
        if (transaction?.status === 'created' || transaction?.status === 'canceled') {
            return []
        }
        if (transaction?.status !== 'success' && transaction?.status !== 'rollback' && transaction?.flow === 'withdraw') {
            return transactionOperations
        }
        if (transaction?.flow === 'deposit' && transaction?.status === 'pending') return rejectFiatTransactionOperation
        return []
    }

    return {
        hasWriteAccess,
        onDetailsClicked,
        onOptionClicked,
        modal,
        onModalClose,
        getTransactionOptions
    }

}


export default useBankTransactions
