import TableLayout from "../../layout/main/TableLayout";
import { Column, DText, Flex, Relative, Row } from "../../../styles/CommonStyles";
import useGetTableIndex from "../../../core/hooks/layout/useGetTableIndex";
import { TradeTypeBadge } from "../../../styles/main/orders";
import { getTradeTypeColor } from "../../../core/utils/theme";
import Text from "../../../core/utils/Text";
import { formatName, formatNumber, getNames } from "../../../core/utils/common";
import DateTime from "../../common/utils/DateTime";
import { SOCKET_URL } from "../../../core/constants/urls";
import UserLink from "../../common/utils/UserLink";
import Operation from "../../common/utils/Operation";
import { balanceSignatureOperations } from "../../../core/constants/operation";
import useCartable from "../../../core/hooks/main/cartable/useCartable";
import ModalLayout from "../../layout/main/ModalLayout";
import BalanceSignatureActionModal from "../../modals/BalanceSignatureActionModal";
import BasicModal from "../../modals/BasicModal";
import { cartableAttributes } from "../../../core/constants/headers";
import { FaInfoCircle } from "react-icons/fa";
import { useState } from "react";
import styled from "styled-components";


const CartableTable = ({
    data
}) => {

    const [visibleNote, setVisibleNote] = useState({});

    const { data: multiSignatures } = data
    const { cs, headers } = cartableAttributes
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked,
        onDeleteClicked,
        actionModal,
        closeActionModal,
        deleteModal,
        closeDeleteModal,
        onSubmitDelete,
        deleteLoading
    } = useCartable()

    const handleMouseEnter = (index) => {
        setVisibleNote(prevState => ({
            ...prevState,
            [index]: true
        }))
    }

    const handleMouseLeave = (index) => {
        setVisibleNote(prevState => ({
            ...prevState,
            [index]: false
        }))
    }

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
            hasWriteAccess
        >
            {multiSignatures?.data?.map((item, idx) => {
                const {
                    FULLNAME: userName
                } = getNames(
                    item.ParamUser.firstName,
                    item.ParamUser.lastName
                )

                return (
                    <Relative>
                        <Row
                            key={item._id}
                            cs={cs}
                            index={idx}
                        >
                            <Column>
                                {getTableIndex(idx)}
                            </Column>
                            <Column>
                                <Flex>
                                    <img
                                        src={
                                            item.params.currency === 'irt' ?
                                                require('../../../assets/images/tooman.png')
                                                :
                                                SOCKET_URL + `assets/icon/${item.params.currency}.png`
                                        }
                                        alt={' '}
                                        width={'24px'}
                                    />
                                    <DText main style={{ margin: '0 8px' }}>
                                        {item.params.currency?.toUpperCase()}
                                    </DText>
                                </Flex>
                            </Column>
                            <Column>
                                <UserLink
                                    _id={item.ParamUser._id}
                                    name={formatName(userName ?? '--')}
                                />

                            </Column>
                            <Column>
                                <UserLink
                                    _id={item.askBy}
                                    name={item.askByUser?.email}
                                />
                            </Column>

                            <Column style={{ cursor: 'pointer', columnGap: '5px', overflow: 'visible', position: 'ralative' }}
                                onMouseLeave={handleMouseLeave.bind(null, idx)}
                                onMouseEnter={handleMouseEnter.bind(null, idx)}
                            >
                                {!!item.note &&
                                    <>
                                        <div>
                                            <FaInfoCircle size={18} />
                                        </div>
                                        <div>
                                            <Text tid='show' />
                                        </div>
                                        {visibleNote[idx] &&
                                            <NoteText>
                                                {item.note}
                                            </NoteText>}
                                    </>
                                }
                            </Column>
                            <Column>
                                {item.tag || ''}
                            </Column>
                            <Column>
                                {formatNumber(item.params.volume)}
                            </Column>
                            <Column center>
                                <TradeTypeBadge color={getTradeTypeColor(item.params.action.toLowerCase())}>
                                    <Text tid={item.params.action.toLowerCase()} />
                                </TradeTypeBadge>
                            </Column>
                            <Column center>
                                <TradeTypeBadge color={getTradeTypeColor(item.status)}>
                                    <Text tid={item.status} />
                                </TradeTypeBadge>
                            </Column>
                            <Column center>
                                <DateTime dt={item.createdAt} />
                            </Column>
                        </Row>
                        <Column operation>
                            <Operation
                                options={hasWriteAccess ? balanceSignatureOperations : []}
                                onOptionClicked={(idx) => onOptionClicked(idx, item)}
                                onDeleteClicked={() => onDeleteClicked(item)}
                                onDetailsClicked={() => onDetailsClicked(item)}
                                hasWriteAccess={item.status === 'pending'}
                                hasDelete={item.status === 'pending' && !hasWriteAccess}
                                deleteTooltip={'delete-cartable'}
                                hasDetails
                            />
                        </Column>
                    </Relative>
                )
            })}
            <ModalLayout
                width={'580px'}
                open={actionModal.open}
                onClose={closeActionModal}
            >
                <BalanceSignatureActionModal
                    data={actionModal}
                    onClose={closeActionModal}
                />
            </ModalLayout>
            <ModalLayout
                width={'580px'}
                open={deleteModal.open}
                onClose={closeDeleteModal}
            >
                <BasicModal
                    head={'delete-signature'}
                    onClose={closeDeleteModal}
                    onSubmit={onSubmitDelete}
                    loading={deleteLoading}
                />
            </ModalLayout>
        </TableLayout>
    )
}

const NoteText = styled.div`
    padding: 1rem 0.5rem;
    border-radius: 5px;

    background-color: ${props => props.theme.noteBg};
    color: ${props => props.theme.noteColor};
    font-size: 0.7rem;
    text-align: right;

    position: absolute;
    top: 0;
    right: 40%;
    max-width: 50%;
    /* height: 100%; */
    z-index: 100;

`


export default CartableTable
