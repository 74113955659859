import ListLayout from "../../components/layout/main/ListLayout";
import { PageCard } from "../../styles/CommonStyles";
import FilterLayout from "../../components/layout/filter/FilterLayout";
import { useGetAllMultiSignatures } from "../../core/services/react-query/multi-signature";
import { balanceMultiSignatureFilterOptions, CacheKeys, cartableSortOptions } from "../../core/constants/filter";
import { useState } from "react";
import { useWindowSize } from "../../core/hooks/common/useWindowSize";
import { TABLET_SIZE } from "../../core/constants/common";
import CartableTable from "../../components/main/cartable/CartableTable";
import RCartableTable from "../../components/responsive/main/cartable/RCartableTable";
import { useAclContext } from "../../core/contexts/acl";


const Cartable = () => {

    const { permissions } = useAclContext()
    const hasDownloadAccess = permissions?.export?.write
    const { width } = useWindowSize()
    const [multiSignatures, setMultiSignatures] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => {
        setMultiSignatures(res)
    }

    const Component = width > TABLET_SIZE ? CartableTable : RCartableTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    hasDownload={hasDownloadAccess}
                    query={useGetAllMultiSignatures}
                    cache={CacheKeys.MULTI_SIGNATURE}
                    onQuerySuccess={onQuerySuccess}
                    options={balanceMultiSignatureFilterOptions}
                    sortOptions={cartableSortOptions}
                    showTotal={{ type: 'CARTABLE' }}
                >
                    <Component
                        data={multiSignatures}
                    />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}


export default Cartable
