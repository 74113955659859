import { Decoration, DText, Flex, Padding } from "../../../styles/CommonStyles";
import { useEffect, useState } from "react";
import Input from "../../common/input/Input";
import { ProfileInfoItem, ProfileInfoAnimate } from "../../../styles/main/profile";
import Text from "../../../core/utils/Text";
import { fadeVariants } from "../../../core/utils/theme";
import useGetProfileOrUser from "../../../core/hooks/main/users/useGetProfileOrUser";
import ProfileDocumentImage from "./ProfileDocumentImage";
import { useChangeUserStatus, useUpdateAUser } from "../../../core/services/react-query/user";
import ModalLayout from "../../layout/main/ModalLayout";
import BasicModal from "../../modals/BasicModal";
import { TextArea } from "../../../styles/main/user";
import { useMainContext } from "../../../core/contexts/main";
import { useGetRoleQuery } from "../../../core/services/react-query/setting/role";
import ActionButton from "../../common/buttons/ActionButton";
import { useUpdateProfile } from "../../../core/services/react-query/profile";
import { useQueryContext } from "../../../core/contexts/query";
import ProfileRole from "./ProfileRole";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import transition from "react-element-popper/animations/transition";
import DateInput from "../../common/input/DateInput";
import { MOBILE_SIZE } from "../../../core/constants/common";
import DatePicker from "react-multi-date-picker";
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";
import { formatAuthDate } from "../../../core/utils/common";


const ProfileInfo = ({
    detail = false,
    user,
    refetch
}) => {


    const { setToast } = useQueryContext()

    const { width } = useWindowSize()
    const { lang, theme } = useMainContext()
    const { profile } = useGetProfileOrUser(detail, user)
    const {
        mutate: changeUserState,
        isLoading,
        isSuccess: userStateChanged
    } = useChangeUserStatus()

    const deleteInitial = {
        show: false, key: null
    }
    const [deleteModal, setDeleteModal] = useState(deleteInitial)
    const closeModal = () => setDeleteModal(deleteInitial)

    const { mutate: updateProfile, isLoading: profileLoading, isSuccess: profileUpdated } = useUpdateProfile()
    const { mutate: updateUser, isSuccess: userUpdated, isLoading: userLoading } = useUpdateAUser()


    const [valid, setValid] = useState(false)
    const [profileCache, setProfileCache] = useState(null)
    const [profileInfo, setProfileInfo] = useState(null)

    const [roleId, setRoleId] = useState(null)
    const { data: role, refetch: getRole } = useGetRoleQuery(roleId, false)

    useEffect(() => {

        if (profile?.role && profile?.role !== 'admin' && profile?.role !== 'user') {
            setRoleId(profile.role)
        }

    }, [profile])

    useEffect(() => {
        if (roleId) {
            getRole()
        }
    }, [roleId])

    useEffect(() => {
        if (profile) {
            const info = {}
            info.role = profile.role
            if (role && profile.role !== 'admin' && profile.role !== 'user') {
                info.role = role
            }
            info.firstName = profile.firstName ? profile.firstName : ''
            info.lastName = profile.lastName ? profile.lastName : ''
            info.fatherName = profile.fatherName ? profile.fatherName : ''
            info.birthDate = profile.birthDate ? profile.birthDate : ''
            info.email = profile.email ? profile.email : ''
            info.mobile = profile.mobile ? profile.mobile : ''
            info.idNo = profile.idNo ? profile.idNo : ''
            info.phone = profile.phone ? profile.phone : ''
            info.zip = profile.address?.zip ? profile.address.zip : ''
            info.country = profile.address?.country || ''
            info.county = profile.address?.county ? profile.address.county : ''
            info.city = profile.address?.city ? profile.address.city : ''
            info.address = profile.address?.line ? profile.address.line : ''
            info.note = profile.note ? profile.note : ''
            setProfileInfo(info)
            setProfileCache(info)
        }
    }, [profile, role])


    useEffect(() => {
        if (userStateChanged) {
            refetch()
            closeModal()
            setDeleteModal(deleteInitial)
        }
    }, [userStateChanged])


    const onInputValueChange = (key, value) => {
        setProfileInfo(state => {
            const newState = { ...state }
            newState[key] = value
            return newState
        })
    }

    useEffect(() => {
        let valid = false
        if (profileInfo) {
            const keys = Object.keys(profileCache)
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i]
                const cacheValue = profileCache[key]
                if (cacheValue !== profileInfo[key]) {
                    valid = true
                    break
                }
            }
        }
        setValid(valid)
    }, [profileInfo])

    useEffect(() => {
        if (userUpdated) refetch()
        if (profileUpdated) setValid(false)
    }, [userUpdated, profileUpdated])


    const getNewProfilePayload = () => {
        const newProfile = {}
        newProfile.firstName = profileInfo.firstName
        newProfile.lastName = profileInfo.lastName
        newProfile.fatherName = profileInfo.fatherName
        newProfile.mobile = profileInfo.mobile
        newProfile.email = profileInfo.email
        newProfile.idNo = profileInfo.idNo
        newProfile.phone = profileInfo.phone
        newProfile.note = profileInfo.note
        newProfile.address = {
            country: 'ir',
            county: profileInfo.county,
            city: profileInfo.city,
            line: profileInfo.address,
            zip: profileInfo.zip
        }

        if (typeof newProfile.birthDate) {
            const birth = profileInfo.birthDate
            const day = birth.day
            const month = birth.monthIndex + 1
            const year = birth.year
            if (day && month && year) {
                newProfile.birthDate = formatAuthDate({
                    day, month, year
                })
            }
        }

        return newProfile
    }


    const onUpdateProfile = () => {

        if (valid) {
            if (detail) {
                const payload = {
                    data: getNewProfilePayload(),
                    id: user?._id
                }
                updateUser(payload)
            } else {
                updateProfile(getNewProfilePayload())
            }
        } else {
            setToast({
                show: true, isError: true,
                message: 'no-changes'
            })
        }
    }

    const onDeleteImage = (key) => {
        setDeleteModal({
            show: true,
            key
        })
    }

    const onSubmitClicked = () => {
        changeUserState({
            id: user?._id,
            data: {
                [deleteModal.key]: null
            }
        })
    }

    return (
        <ProfileInfoAnimate
            variants={fadeVariants}
            animate='in'
            exit='out'
            initial='out'
        >
            <Padding padding={'20px'}>
                <Flex>
                    <Decoration />
                    <DText main>
                        <Text tid='profile-info-note' />
                    </DText>
                </Flex>
                <Flex fw wrap>
                    {profileInfo &&
                        Object.keys(profileInfo).map(key => {
                            const value = profileInfo[key]

                            return (
                                <ProfileInfoItem
                                    key={key}
                                >
                                    {(key === 'birthDate' && detail) &&

                                        <DatePicker
                                            calendar={lang === 'fa' && persian}
                                            locale={lang === 'fa' && persian_fa}
                                            value={value}
                                            onChange={(v) => onInputValueChange(key, v)}
                                            animations={[transition()]}
                                            render={<DateInput isProfile label={'birthDate'} />}
                                            className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${width < MOBILE_SIZE && 'rmdp-mobile'}`}
                                            calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
                                        />
                                    }
                                    {key === 'address' &&
                                        <TextArea
                                            width={'calc(100% - 14px)'}
                                            minHeight={'40px'}
                                            value={value}
                                            placeholder={lang === 'en' ? 'address' : 'آدرس'}
                                            onChange={(e) => onInputValueChange(key, e?.target?.value)}
                                        />
                                    }
                                    {key === 'note' &&
                                        <TextArea
                                            width={'calc(100% - 14px)'}
                                            minHeight={'40px'}
                                            value={value}
                                            placeholder={lang === 'en' ? 'Note' : 'توضیحات'}
                                            onChange={(e) => onInputValueChange(key, e?.target?.value)}
                                        />
                                    }
                                    {key === 'role' &&
                                        <ProfileRole
                                            refetch={refetch}
                                            role={value}
                                            _id={detail ? user?._id : profile?._id}
                                            isProfile={!detail}
                                        />
                                    }
                                    {(key !== 'role' && key !== 'address' && key !== 'note' && (key !== 'birthDate' || !detail)) &&
                                        <Input
                                            value={value}
                                            label={key}
                                            onInputChange={(v) => onInputValueChange(key, v)}
                                            disabled={!detail}
                                        />
                                    }

                                </ProfileInfoItem>
                            )
                        })
                    }
                </Flex>
            </Padding>
            {detail &&
                <Flex fw style={{ margin: '30px 0' }}>
                    <ActionButton
                        active={valid}
                        onClick={onUpdateProfile}
                        loading={userLoading}
                        width={'280px'}
                    >
                        <Text tid={detail ? 'update-user' : 'update-profile'} />
                    </ActionButton>
                </Flex>
            }

            <Flex fw wrap justify={'space-around'}>
                <ProfileDocumentImage
                    src={profile?.idCard}
                    type={'idCard'}
                    detail={detail}
                    onDeleteImage={onDeleteImage}
                />
                <ProfileDocumentImage
                    src={profile?.selfi}
                    type={'selfi'}
                    detail={detail}
                    onDeleteImage={onDeleteImage}
                />
            </Flex>

            <ModalLayout
                onClose={closeModal}
                open={deleteModal.show}
                width={'580px'}
            >
                <BasicModal
                    head={`delete-${deleteModal.key}`}
                    onClose={closeModal}
                    onSubmit={onSubmitClicked}
                    loading={isLoading}
                />
            </ModalLayout>
        </ProfileInfoAnimate>
    )
}

export default ProfileInfo
