import { Column, DText, Flex, Row } from '../../../styles/CommonStyles'
import { SOCKET_URL } from '../../../core/constants/urls'
import { formatNumber } from '../../../core/utils/common'
import TableLayout from '../../layout/main/TableLayout'
import { fullnodeStatAttributes } from '../../../core/constants/headers'
import { useMemo } from 'react'
import { useFullNode } from '../../../core/hooks/useFullNode'
import { TradeTypeBadge } from '../../../styles/main/orders'
import { Link } from "react-router-dom";

const FullnodeStatsTable = ({ data }) => {
	const { data: stats } = data
	const { cs, headers } = fullnodeStatAttributes

	const { CUMULATIVE_STATUSES, getStatusInfo, mergeSymbols } = useFullNode()

	const mergedSymbols = mergeSymbols(stats?.data)

	return (
		<TableLayout headers={headers} cs={cs} data={data} hasPagination={false} hasWriteAccess>
			{mergedSymbols.toReversed().map((stat, idx) => ( // The reverse data must handle from backend
				<Row cs={cs} index={idx} key={stat.symbol}>
					<Column>{idx + 1}</Column>
					<Column>
						<Flex>
							<img
								src={
									stat.symbol === 'irt'
										? require('../../../assets/images/tooman.png')
										: SOCKET_URL + `assets/icon/${stat.symbol}.png`
								}
								width='32px'
								alt=' '
							/>
							<DText style={{ margin: '0 8px' }}>{stat.symbol?.toUpperCase()}</DText>
						</Flex>
					</Column>
					<Column style={{ lineBreak: 'anywhere' }}>
						{stat?.status?.length && (
							<div style={{ display: 'flex', flexDirection: 'column', gap: '0.375rem' }}>
								{stat.status.map((item) => {
									const statusInfo = getStatusInfo(item.cumulativeStatus)

									return (
										<div key={item.cumulativeStatus}>
											<Link to={`/fullnode?tab=2&symbol=${stat.symbol}&status=${item.cumulativeStatus}`}>
												<TradeTypeBadge color={statusInfo?.color} style={{ margin: '0 8px' }}>
													{item.cumulativeStatus}: {formatNumber(item.amount)}{' '}
													{stat.symbol?.toUpperCase()}
												</TradeTypeBadge>
											</Link>
										</div>
									)
								})}
							</div>
						)}
					</Column>
					<Column>
						{stat?.status?.length && (
							<div style={{ display: 'flex', flexDirection: 'column', gap: '0.375rem' }}>
								{stat.status.map((item) => {
									const statusInfo = getStatusInfo(item.cumulativeStatus)

									return (
										<div key={item.cumulativeStatus}>
											<Link to={`/fullnode?tab=2&symbol=${stat.symbol}&status=${item.cumulativeStatus}`}>
												<TradeTypeBadge color={statusInfo?.color} style={{ margin: '0 8px' }}>
													{item.cumulativeStatus}: {formatNumber(item.count)}{' '}
												</TradeTypeBadge>
											</Link>
										</div>
									)
								})}
							</div>
						)}
					</Column>
				</Row>
			))}
		</TableLayout>
	)
}

export default FullnodeStatsTable
