import { useMutation, useQuery } from 'react-query'
import { useMainContext } from '../../../../contexts/main'
import { makeQueryFilters } from '../../../../utils/query-maker'
import { filterFetch } from '../../../fetch-api/get'
import { deleteApi } from '../../../fetch-api/delete'

export const NOTIFICATION_KEYS = {
    GET_ALL_NOTIFICATIONS: 'get-all-notifications',
}

export const useGetNotifications = (filters, pagination, extra) => {
    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = { ...makeQueryFilters(newFilters, lang), ...pagination }

    return useQuery(
        NOTIFICATION_KEYS.GET_ALL_NOTIFICATIONS,
        () => filterFetch(params, 'notifications'),
        {
            cacheTime: 1 * 60 * 1000,
            select: (res) => res.data,
        }
    )
}

export const useDeleteNotification = () => {

    return useMutation(
        NOTIFICATION_KEYS.GET_ALL_NOTIFICATIONS,
        (id) => deleteApi(`notifications/${id}`),
        {
            cacheTime: 1 * 60 * 1000,
            select: (res) => res.data,
        }
    )
}
